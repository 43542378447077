import _ from 'lodash'
import {DEVICE_MODE_STATE, PENDING_REQ_KEY, PROFILE_TYPE} from 'lib/utils/constants'
import {FEATURE_KEYS, isFeatureSupported} from 'lib/utils/featureKeys'
import {isSiteGridTied, isSiteGridAgnostic, isIQGWScheduleSupportedSite} from '../../../utils/siteDetails'

// Request Pending :: Profile
export function isProfileRequestPending(details, requestedConfigType, thisProfile) {
  const profile = details?.profile
  const reqProfile = details && details[requestedConfigType]?.profile

  if (thisProfile === profile) {
    return false
  }

  if (thisProfile) {
    return !!(reqProfile && thisProfile === reqProfile)
  }

  return !!(reqProfile && profile !== reqProfile)
}

// Any Request Pending :: Profile
export function isAnyRequestPendingForProfile(details) {
  return (
    isProfileRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT) ||
    isProfileRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG)
  )
}

// Request Pending :: batteryBackupPercentage
export function isBatteryBackupPercentageRequestPending(details, requestedConfigType, profile) {
  const reqProfile = details && details[requestedConfigType]?.profile
  const batteryBackupPercentage = details?.batteryBackupPercentage
  const reqBatteryBackupPercentage = details && details[requestedConfigType]?.batteryBackupPercentage

  if (profile) {
    return (
      profile === reqProfile && !!(reqBatteryBackupPercentage && batteryBackupPercentage !== reqBatteryBackupPercentage)
    )
  }

  return !!(reqBatteryBackupPercentage && batteryBackupPercentage !== reqBatteryBackupPercentage)
}

// Request Pending :: operationModeSubType
export function isAfterPeakRequestPending(details, requestedConfigType) {
  const operationModeSubType = details?.operationModeSubType
  const reqOperationModeSubType = details && details[requestedConfigType]?.operationModeSubType
  return !!(
    !_.isNil(operationModeSubType) &&
    !_.isNil(reqOperationModeSubType) &&
    operationModeSubType !== reqOperationModeSubType
  )
}

// Request Pending :: operationModePvType
export function isIncreaseSelfConsumptionRequestPending(details, requestedConfigType) {
  const operationModePvType = details?.operationModePvType
  const reqOperationModePvType = details && details[requestedConfigType]?.operationModePvType
  return !!(
    !_.isNil(operationModePvType) &&
    !_.isNil(reqOperationModePvType) &&
    operationModePvType !== reqOperationModePvType
  )
}

// Request Pending :: veryLowSoc
export function isVeryLowSocRequestPending(details, requestedConfigType) {
  const veryLowSoc = details?.veryLowSoc
  const reqVeryLowSoc = details && details[requestedConfigType]?.veryLowSoc

  return !!(reqVeryLowSoc && veryLowSoc !== reqVeryLowSoc)
}

// Request Pending :: Any Profile
export function isRequestPendingForProfile(details) {
  return (
    // profile
    isProfileRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT) ||
    isProfileRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG) ||
    // batteryBackupPercentage
    isAnyRequestPendingForBatteryReserve(details) ||
    // operationModeSubType
    isAnyRequestPendingForOperationModeSubType(details) ||
    // operationModePvType
    isIncreaseSelfConsumptionRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT) ||
    isIncreaseSelfConsumptionRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG) ||
    // veryLowSoc
    isAnyRequestPendingForBatteryShutdown(details)
  )
}

// Request Pending :: This Profile (Savings | Self-Consumption | Full Backup )
export function isThisProfileRequestPending(details, profile) {
  const isPending =
    // profile
    isProfileRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT, profile) ||
    isProfileRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG, profile)

  if (profile === PROFILE_TYPE.COST_SAVINGS) {
    return (
      isPending ||
      isThisProfileRequestPendingForBatteryReserve(details, profile) ||
      isAnyRequestPendingForOperationModeSubType(details)
    )
  }

  if (profile === PROFILE_TYPE.SELF_CONSUMPTION) {
    return (
      isPending ||
      isThisProfileRequestPendingForBatteryReserve(details, profile) ||
      isIncreaseSelfConsumptionRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT) ||
      isIncreaseSelfConsumptionRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG)
    )
  }

  if (profile === PROFILE_TYPE.BACKUP_ONLY) {
    return isPending || isThisProfileRequestPendingForBatteryReserve(details, profile)
  }

  return false
}

// Request Pending :: Discharge Battery To Grid
export function isRequestPendingForDischargeBatteryToGridByRequestType(details, requestType) {
  return (
    // dischargeToGrid
    isDischargeToGridRequestPending(details, requestType) ||
    // discharge startTime
    isDischargeStartTimeRequestPending(details, requestType) ||
    // discharge endTime
    isDischargeEndTimeRequestPending(details, requestType)
  )
}

// Any Request Pending :: Discharge Battery To Grid
export function isAnyRequestPendingForDischargeBatteryToGrid(details) {
  return (
    isRequestPendingForDischargeBatteryToGridByRequestType(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT) ||
    isRequestPendingForDischargeBatteryToGridByRequestType(details, PENDING_REQ_KEY.REQUESTED_CONFIG)
  )
}

// Request Pending :: Discharge To Grid
export function isDischargeToGridRequestPending(details, requestedConfigType) {
  const dischargeToGrid = details?.dtgControl?.enabled
  const reqDischargeToGrid = details && details[requestedConfigType]?.dtgControl?.enabled
  return !!(!_.isNil(dischargeToGrid) && !_.isNil(reqDischargeToGrid) && dischargeToGrid !== reqDischargeToGrid)
}

// Request Pending :: Discharge Start Time
export function isDischargeStartTimeRequestPending(details, requestedConfigType) {
  const dischargeStartTime = details?.dtgControl?.startTime
  const reqDischargeBeginTime = details && details[requestedConfigType]?.dtgControl?.startTime
  return !!(
    !_.isNil(dischargeStartTime) &&
    !_.isNil(reqDischargeBeginTime) &&
    dischargeStartTime !== reqDischargeBeginTime
  )
}

// Request Pending :: Discharge End Time
export function isDischargeEndTimeRequestPending(details, requestedConfigType) {
  const dischargeEndTime = details?.dtgControl?.endTime
  const reqDischargeEndTime = details && details[requestedConfigType]?.dtgControl?.endTime
  return !!(!_.isNil(dischargeEndTime) && !_.isNil(reqDischargeEndTime) && dischargeEndTime !== reqDischargeEndTime)
}

// Request Pending :: Discharge Schedule Supported
export function isDischargeScheduleSupportedRequestPending(details, requestedConfigType) {
  const scheduleSupported = details?.dtgControl?.scheduleSupported
  const reqScheduleSupported = details && details[requestedConfigType]?.dtgControl?.scheduleSupported
  return !!(!_.isNil(scheduleSupported) && !_.isNil(reqScheduleSupported) && scheduleSupported !== reqScheduleSupported)
}

// Request Pending :: Discharge Locked
export function isDischargeLockedRequestPending(details, requestedConfigType) {
  const locked = details?.dtgControl?.locked
  const reqLocked = details && details[requestedConfigType]?.dtgControl?.locked
  return !!(!_.isNil(locked) && !_.isNil(reqLocked) && locked !== reqLocked)
}

// Request Pending :: chargeFromGrid
export function isChargeFromGridRequestPending(details, requestedConfigType) {
  const chargeFromGrid = details?.chargeFromGrid
  const reqChargeFromGrid = details && details[requestedConfigType]?.chargeFromGrid
  return !!(!_.isNil(chargeFromGrid) && !_.isNil(reqChargeFromGrid) && chargeFromGrid !== reqChargeFromGrid)
}

// Request Pending :: chargeBeginTime
export function isChargeBeginTimeRequestPending(details, requestedConfigType) {
  const chargeBeginTime = details?.chargeBeginTime
  const reqChargeBeginTime = details && details[requestedConfigType]?.chargeBeginTime
  return !!(!_.isNil(chargeBeginTime) && !_.isNil(reqChargeBeginTime) && chargeBeginTime !== reqChargeBeginTime)
}

// Request Pending :: chargeEndTime
export function isChargeEndTimeRequestPending(details, requestedConfigType) {
  const chargeEndTime = details?.chargeEndTime
  const reqChargeEndTime = details && details[requestedConfigType]?.chargeEndTime
  return !!(!_.isNil(chargeEndTime) && !_.isNil(reqChargeEndTime) && chargeEndTime !== reqChargeEndTime)
}

// Request Pending :: Charge Locked
export function isChargeFromGridLockedRequestPending(details, requestedConfigType) {
  const locked = details?.cfgControl?.locked
  const reqLocked = details && details[requestedConfigType]?.cfgControl?.locked
  return !!(!_.isNil(locked) && !_.isNil(reqLocked) && locked !== reqLocked)
}

// Request Pending :: CFG Schedule Supported
export function isChargeFromGridScheduleSupportedRequestPending(details, requestedConfigType) {
  const scheduleSupported = details?.cfgControl?.scheduleSupported
  const reqScheduleSupported = details && details[requestedConfigType]?.cfgControl?.scheduleSupported
  return !!(!_.isNil(scheduleSupported) && !_.isNil(reqScheduleSupported) && scheduleSupported !== reqScheduleSupported)
}

// Request Pending :: chargeFromGridScheduleEnabled
export function isScheduleEnabledRequestPending(details, requestedConfigType) {
  const chargeFromGridScheduleEnabled = details?.chargeFromGridScheduleEnabled

  const reqScheduleEnabled = details && details[requestedConfigType]?.chargeFromGridScheduleEnabled

  return !!(
    !_.isNil(chargeFromGridScheduleEnabled) &&
    !_.isNil(reqScheduleEnabled) &&
    chargeFromGridScheduleEnabled !== reqScheduleEnabled
  )
}

// Any Request Pending :: CFG with IQGW site
export function isAnyRequestPendingForChargeBatteryFromGridWithIQGWSite(details) {
  return isIQGWScheduleSupportedSite() && isAnyRequestPendingForChargeBatteryFromGrid(details)
}

// Any Request Pending :: Charge Battery From Grid
export function isAnyRequestPendingForChargeBatteryFromGrid(details) {
  return (
    // chargeFromGrid
    isRequestPendingForChargeBatteryFromGrid(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT) ||
    isRequestPendingForChargeBatteryFromGrid(details, PENDING_REQ_KEY.REQUESTED_CONFIG)
  )
}

// Request Pending :: Charge Battery From Grid
export function isRequestPendingForChargeBatteryFromGrid(details, requestType) {
  const isPending =
    // chargeFromGrid
    isChargeFromGridRequestPending(details, requestType) ||
    // chargeBeginTime
    isChargeBeginTimeRequestPending(details, requestType) ||
    // chargeEndTime
    isChargeEndTimeRequestPending(details, requestType)

  if (isSiteGridTied()) {
    return isPending
  }

  if (!isSiteGridTied() && !isFeatureSupported(FEATURE_KEYS.CFG_NEW_LOGIC)) {
    isPending ||
      // chargeFromGridScheduleEnabled

      isScheduleEnabledRequestPending(details, requestType)
  }
  if (isSiteGridAgnostic()) {
    return (
      isPending ||
      // chargeFromGridScheduleEnabled

      isScheduleEnabledRequestPending(details, requestType)
    )
  }

  return (
    // chargeFromGrid
    isChargeFromGridRequestPending(details, requestType)
  )
}

// Any Request Pending :: Battery Reserve
export function isAnyRequestPendingForBatteryReserve(details) {
  return (
    // batteryBackupPercentage
    isBatteryBackupPercentageRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT) ||
    isBatteryBackupPercentageRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG)
  )
}

// Any Request Pending :: Battery Reserve for this Profile (Savings | Self-Consumption | Full Backup )
export function isThisProfileRequestPendingForBatteryReserve(details, profile) {
  return (
    // batteryBackupPercentage
    isBatteryBackupPercentageRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT, profile) ||
    isBatteryBackupPercentageRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG, profile)
  )
}

// Any Request Pending :: Battery Reserve or Battery Shutdown
export function isAnyRequestPendingForBatteryReserveOrShutdown(details) {
  return (
    // batteryBackupPercentage
    isAnyRequestPendingForBatteryReserve(details) ||
    // veryLowSoc
    isAnyRequestPendingForBatteryShutdown(details)
  )
}

// Any Request Pending :: Battery Shutdown
export function isAnyRequestPendingForBatteryShutdown(details) {
  return (
    // veryLowSoc
    isVeryLowSocRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT) ||
    isVeryLowSocRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG)
  )
}

// Any Request Pending :: operationModeSubType
export function isAnyRequestPendingForOperationModeSubType(details) {
  return (
    // operationModeSubType
    isAfterPeakRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT) ||
    isAfterPeakRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG)
  )
}

export function isAnyRequestPendingForChargingModeForThirdParty(details) {
  return isChargingModeRequestPending(details, DEVICE_MODE_STATE.IN_PROGRESS)
}

export function isChargingModeRequestPending(details, requestedConfigType) {
  const thirdPartyEvseDetails = details.devices
  const isChargingModeInProgress = thirdPartyEvseDetails?.thirdPartyEvse?.some(
    evse => evse.chargeModeStatus === requestedConfigType
  )
  return isChargingModeInProgress || details.chargeModeStatus == DEVICE_MODE_STATE.IN_PROGRESS
}
